import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import '../components/styles/seance.css';
import Layout from '../components/layout';
import HeaderRandom from '../components/headerRandom';
import Seo from '../components/seo';

const CardiacCoherence = () => (
  <Layout>
    <Seo />
    <HeaderRandom />
    <div id='title'>
      <h1>LA COHÉRENCE CARDIAQUE</h1>
    </div>

    <div className='container division'>
      <div className='row'>
        <div className='col-md-6'>
          <p>
            C’est un superbe outil, que j’utilise dans chacune de mes séances.<br />
            D’ailleurs, je vous expliquerai comment l’utiliser par la suite sur vous même.
          </p>
          <p>Lors des séances, elle permet un meilleur lâcher prise.</p>
          <p><b>Par quel procédé ?</b></p>
          <p>Tout se passe au niveau du système nerveux autonome.</p>
          <p>Ce dernier est divisé en 2 hémisphères,  d’un côté nous avons le sympathique qui est responsable par exemple du stress, on peut y retrouver les hormones telles que l’adrénaline, le cortisol et d’un autre côté, il y a le parasympathique qui permet la relaxation, le repos  la réparation, les hormones que nous pouvons retrouvées dans ce cas sont généralement, la DHEA ( hormone de la jeunesse), l’ocytocine et l'acétylcholine.</p>
          <p>La cohérence cardiaque permet par le biais de la respiration de recentrer ces 2 systèmes afin de conserver une meilleure santé.</p>
          <p>J’ai été formée à la cohérence cardiaque par le célèbre Docteur David O’HARE, ambassadeur de la méthode en France et en international, et auteur de célèbres best-sellers dont la méthode Cohérence cardiaque 365.
          </p>
        </div>
        <div className='col-md-6 sticky'>
          <StaticImage src='../img/coherence-cardiaque.jpg' placeholder='blurred' alt='coherence cardiaque' />
        </div>
      </div>
    </div>
    <div className='container division'>
      <div className='row'>
        <div className='col-md-6' />
        <div className='col-md-6 t-right'>
          <StaticImage src='../img/coherence-cardiaque-365.jpg' placeholder='blurred' alt='coherence cardiaque 365' />
        </div>
      </div>
    </div>
  </Layout>
);

export default CardiacCoherence;
